import { Box, styled } from '@mui/material'
import { X } from 'react-feather'

import { red10, red50 } from '@theme'

const REASONS = [
  'Search and add wine and spirits to your menus from our database, just like beer!',
  'Add unlimited non-beer items like food or merchandise to your menu',
  'Unlimited users',
  'API access to take your menus to a new level',
]

const Reason = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  backgroundColor: red10,
  fontWeight: 600,
  lineHeight: '18px',
})

const ReasonIcon = styled(X)(({ theme }) => ({
  color: red50,
  size: 18,
  marginRight: theme.spacing(4),
  minWidth: 18,
}))

const LosePremiumFeatures = () => (
  <>
    {REASONS.map((reason) => (
      <Reason key={reason} p={4} mb={3} borderRadius={1}>
        <ReasonIcon />
        {reason}
      </Reason>
    ))}
  </>
)

export default LosePremiumFeatures
