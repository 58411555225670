import { makeAutoObservable } from 'mobx'

import { VenuePermission } from '@app/interfaces/permissions'

import Subscription from './Subscription'
import User from './CurrentUser'

export default class Permissions {
  subscription: Subscription

  constructor(subscription: Subscription) {
    this.subscription = subscription

    makeAutoObservable(this, { subscription: false })
  }

  hasPermission = (permission: VenuePermission): boolean => {
    return Boolean(this.subscription.member[permission])
  }

  get user(): User {
    return this.subscription.user
  }

  get isDenMaster(): boolean {
    return this.user.isDenMaster || false
  }

  get isFeatureGated(): boolean {
    const isVerified = Boolean(this.subscription.venue.verified)

    return this.subscription.isTrialing || !isVerified
  }

  get isGreenTurtle(): boolean {
    return this.user.isGreenTurtle || false
  }

  get canCreateMenu(): boolean {
    if (this.isGreenTurtle) return false

    return this.hasPermission(VenuePermission.createMenu)
  }

  get canDeleteMenu(): boolean {
    if (this.isGreenTurtle) return false

    return this.hasPermission(VenuePermission.deleteMenu)
  }

  get canUpdateMenuName(): boolean {
    if (this.isGreenTurtle) return false

    return this.hasPermission(VenuePermission.updateMenuName)
  }

  get canUpdateSectionName(): boolean {
    return this.hasPermission(VenuePermission.updateSectionName)
  }

  get canAddAndRemoveBeers(): boolean {
    return this.hasPermission(VenuePermission.addAndRemoveBeers)
  }

  get canAddAndRemoveSections(): boolean {
    return this.hasPermission(VenuePermission.addAndRemoveSections)
  }

  get canEditBeerInfo(): boolean {
    return this.hasPermission(VenuePermission.editBeerInfo)
  }

  get canListPrintThemes(): boolean {
    return this.hasPermission(VenuePermission.listPrintThemes)
  }

  get canCustomizePrintMenus(): boolean {
    return this.hasPermission(VenuePermission.customizePrintMenus)
  }

  get canListDigitalBoards(): boolean {
    return this.hasPermission(VenuePermission.listDigitalBoards)
  }

  get canEditSite(): boolean {
    return this.hasPermission(VenuePermission.editSite)
  }

  get canListWebsiteThemes(): boolean {
    if (this.isDenMaster) return false

    return this.hasPermission(VenuePermission.listWebsiteThemes)
  }

  get canListMenuAnnouncements(): boolean {
    if (this.isDenMaster) return false

    return this.hasPermission(VenuePermission.listMenuAnnouncements)
  }

  get canListBusinessAnalytics(): boolean {
    if (this.isFeatureGated) return false

    return this.hasPermission(VenuePermission.listBusinessAnalytics)
  }

  get canListVenueAnalytics(): boolean {
    if (this.isFeatureGated) return false

    return this.hasPermission(VenuePermission.listVenueAnalytics)
  }

  get canListNearbyAnalytics(): boolean {
    if (this.isFeatureGated) return false

    return this.hasPermission(VenuePermission.listNearbyAnalytics)
  }

  get canShowLocationInformation(): boolean {
    return this.hasPermission(VenuePermission.showLocationInformation)
  }

  get canShowLocationDetails(): boolean {
    if (this.isDenMaster) return false

    return this.hasPermission(VenuePermission.showLocationDetails)
  }

  get canListHours(): boolean {
    if (this.isDenMaster) return false

    return this.hasPermission(VenuePermission.listHours)
  }

  get canListInvoices(): boolean {
    return this.hasPermission(VenuePermission.listInvoices)
  }

  get canListInsights(): boolean {
    return !this.isDenMaster
  }

  get canManageMembers(): boolean {
    return (
      this.subscription.userId === this.user.id ||
      this.hasPermission(VenuePermission.manageMembers)
    )
  }

  get canCreateEvent(): boolean {
    return this.hasPermission(VenuePermission.createEvent)
  }
}
