import { Dialog as MuiDialog, styled } from '@mui/material'

import { white } from '@theme'

const DialogDarkBackground = styled(MuiDialog)({
  '.MuiDialog-paper': {
    backgroundImage:
      'url("https://assets.untappd.com/business/images/premium_tier_modal_background.png")',
    color: white,
  },

  '.MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
    minWidth: 600,
  },
})

export default DialogDarkBackground
