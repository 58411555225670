import { ReactElement } from 'react'
import { createElement, ComponentType } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { Theme } from '@components'

const queryClient = new QueryClient()

type Imports = {
  [key: string]: () => Promise<{ default: ComponentType }>
}

export default function componentLoader(imports: Imports = {}) {
  document.addEventListener('DOMContentLoaded', () => {
    const components = Array.prototype.slice.call(
      document.querySelectorAll('[data-component]'),
    )

    components.forEach((el) => {
      const data = el.dataset
      const load = imports[data.component]

      if (!load) {
        throw new Error(
          `Component (${data.component}) not found.
          Make sure you have resolved it correctly in the imports object`,
        )
      }

      load().then(({ default: Component }) => {
        const element: ReactElement = createElement(
          Component,
          JSON.parse(data.reactProps),
        )
        const root = createRoot(el)
        root.render(
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <QueryClientProvider client={queryClient}>
              <Theme>{element}</Theme>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </LocalizationProvider>,
        )
      })
    })
  })
}
