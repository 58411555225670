import { useMemo, useState } from 'react'
import * as React from 'react'
import {
  Menu,
  MenuItem,
  Box,
  Link as MuiLink,
  Button as MuiButton,
  styled,
} from '@mui/material'
import { ChevronDown, ChevronUp } from 'react-feather'
import { gray60, useDown, yellow30 } from '@theme'

import Truncated from '@app/components/Truncated'
import ScheduledDowngrade from './ScheduledDowngrade'
import AnnouncekitWidget from '@components/AnnouncekitWidget'

const Button = styled(MuiButton)({
  backgroundColor: 'transparent',
  borderRadius: 0,
  padding: '0 24px',
  height: '100%',
  color: gray60,
  '&:hover, &:active': {
    backgroundColor: 'transparent',
    color: gray60,
  },
  '&:focus': {
    borderColor: 'transparent',
  },
})

const Link = styled(MuiLink)({
  color: gray60,
  '&:hover': {
    color: gray60,
  },
})

const UpgradeLink = styled(Link)({
  alignItems: 'center',
  backgroundColor: yellow30,
  color: gray60,
  display: 'flex',
  fontWeight: 700,
  justifyContent: 'center',
  paddingLeft: 14,
  paddingRight: 14,
  textAlign: 'center',
  '&:hover': {
    textDecoration: 'none',
  },
})

const ACCOUNT_LINKS = [
  {
    title: 'Settings',
    href: '/account',
  },
  {
    title: 'Manage Users',
    href: '/members',
  },
  {
    title: 'Billing & Invoices',
    href: '/invoices/utfb',
  },
  {
    title: 'Refer a Friend',
    href: '/app/referral',
  },
  {
    title: 'Support',
    href: 'https://untappd.zendesk.com',
  },
  {
    title: 'Logout',
    href: '/logout',
  },
]

interface HeaderRightProps {
  canManageUsers: boolean
  isPremium: boolean
  isTrialing: boolean
  name: string
  subscriptionId: string
  userIcon: string
  scheduledDowngradeDate: string | null
  hasPremiumEntitlements: boolean
}

const HeaderRight = ({
  canManageUsers,
  isPremium,
  isTrialing,
  name,
  subscriptionId,
  userIcon,
  scheduledDowngradeDate,
  hasPremiumEntitlements,
}: HeaderRightProps) => {
  const isPaymentScreen = window.location.pathname.includes('upgrade')
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const downSm = useDown('sm')

  const links = useMemo(() => {
    if (canManageUsers) return ACCOUNT_LINKS

    return ACCOUNT_LINKS.filter((link) => link.title !== 'Manage Users')
  }, [canManageUsers])

  const handleClick = (e: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(e.currentTarget)

  const UserIcon = () => (
    <img src={userIcon} alt="User icon" style={{ minWidth: 24 }} />
  )

  const chevron = anchorEl ? (
    <ChevronUp aria-label="Chevron up icon" />
  ) : (
    <ChevronDown aria-label="Chevron down icon" />
  )

  const upgradeUrl = `/app/venues/${subscriptionId}/upgrade/plans`

  const showPremiumLink =
    subscriptionId &&
    !isTrialing &&
    !isPremium &&
    !hasPremiumEntitlements &&
    !isPaymentScreen

  return (
    <Box display="flex" height="100%">
      <AnnouncekitWidget />

      {isPremium && scheduledDowngradeDate && (
        <ScheduledDowngrade date={scheduledDowngradeDate} />
      )}

      {showPremiumLink && (
        <UpgradeLink href={upgradeUrl} ml={4}>
          Upgrade to Premium
        </UpgradeLink>
      )}
      <Button
        className={anchorEl ? 'active' : undefined}
        startIcon={!downSm && <UserIcon />}
        endIcon={chevron}
        variant="text"
        onClick={handleClick}
      >
        <Truncated>{name}</Truncated>
      </Button>
      <Menu
        transitionDuration={0}
        MenuListProps={{ style: { padding: 0 } }}
        PaperProps={{
          style: { borderRadius: 0, marginLeft: 20, marginTop: 2 },
        }}
        elevation={2}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {links.map((link) => (
          <MenuItem
            key={link.title}
            href={link.href}
            divider
            color="inherit"
            underline="none"
            component={Link}
          >
            <Box px={1} py={2} fontWeight="fontWeightBold" width={200}>
              {link.title}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default HeaderRight
