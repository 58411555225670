import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  styled,
} from '@mui/material'

import { gray60, white } from '@theme'

import Dialog from '../DialogDarkBackground'
import PremiumFeature, { PremiumIcons } from '../PremiumFeature'

const DialogAction = styled(Button)({
  color: gray60,
  backgroundColor: white,
})

const DialogActionOutlined = styled(Button)({
  color: white,
  borderColor: white,
  backgroundColor: 'transparent',
})

export interface EndDialogProps {
  open: boolean
  isBillingUser: boolean
  maxUsers: number
  maxGenericItems: number
  userCount: number
  genericItemCount: number
  handleDismiss: () => void
  handleUpgradeRequest: () => void
  handleUpgradeToPremium: () => void
  handleKeepEssentials: () => void
}

const EndDialog = ({
  open,
  isBillingUser,
  maxUsers,
  maxGenericItems,
  userCount,
  genericItemCount,
  handleDismiss,
  handleUpgradeRequest,
  handleUpgradeToPremium,
  handleKeepEssentials,
}: EndDialogProps) => (
  <Dialog open={open}>
    <DialogContent dividers={false}>
      <Box
        component="img"
        my={8}
        mx="auto"
        src="https://assets.untappd.com/business/images/utfb_premium_logo.svg"
        alt="Untappd for Business Premium"
      />
      <Box fontSize={24} fontWeight="fontWeightBold" textAlign="center">
        You have reached the end of your Free Untappd for Business Premium
        Experience
      </Box>

      <Box
        alignItems="center"
        textAlign="center"
        bgcolor="#FFFFFF1A"
        borderRadius={1}
        display="flex"
        fontWeight="fontWeightBold"
        gap={4}
        my={6}
        px="100px"
        py={4}
      >
        {isBillingUser
          ? 'Would you like to upgrade and keep the Premium Features shown below?'
          : 'This account needs to upgrade in order keep the Premium Features shown below'}
      </Box>
      <Box display="flex" alignItems="start" flexDirection="column">
        <PremiumFeature
          icon={PremiumIcons.SEARCH_ITEM}
          description={
            <>
              <strong>
                The ability to search and add wine and spirits - just like you
                can do with beer.
              </strong>
            </>
          }
        />
        <PremiumFeature
          icon={PremiumIcons.GENERIC_ITEM_LIMIT}
          description={
            <>
              <strong>
                Add unlimited non-beer items like food or merchandise to your
                menu.
              </strong>{' '}
              You currently have [{genericItemCount}] non-beer item(s) and
              without Untappd for Business Premium you will have a limit of{' '}
              {maxGenericItems} non-beer items.
            </>
          }
        />
        <PremiumFeature
          icon={PremiumIcons.USER_LIMIT}
          description={
            <>
              <strong>Unlimited users.</strong> You currently have [{userCount}]
              user(s) and without Untappd for Business Premium you will have a
              limit of {maxUsers} users
            </>
          }
        />
        <PremiumFeature
          icon={PremiumIcons.API_ACCESS}
          description={
            <strong>API access to take your menus to a new level.</strong>
          }
        />
      </Box>
    </DialogContent>
    <DialogActions
      disableSpacing
      sx={{
        flexDirection: 'column',
        boxShadow: `0 0 4px ${white}`,
        pt: 5,
        gap: 5,
      }}
    >
      {isBillingUser ? (
        <>
          <DialogAction
            fullWidth
            onClick={handleUpgradeToPremium}
            data-analytics="complimentary_premium_end_modal_upgrade"
          >
            Upgrade to Premium
          </DialogAction>
          <DialogAction
            fullWidth
            onClick={handleKeepEssentials}
            data-analytics="complimentary_premium_end_modal_decline_upgrade"
          >
            Keep me on Untappd for Business
          </DialogAction>
        </>
      ) : (
        <>
          <DialogAction
            fullWidth
            onClick={handleUpgradeRequest}
            data-analytics="complimentary_premium_end_modal_request_upgrade"
          >
            Request to Upgrade
          </DialogAction>
          <DialogActionOutlined
            fullWidth
            onClick={handleDismiss}
            data-analytics="complimentary_premium_end_modal_decline_request_upgrade"
          >
            Dismiss
          </DialogActionOutlined>
        </>
      )}
      <Box alignSelf="flex-start" fontSize={14}>
        If you have any questions, please contact us at{' '}
        <Box component="span" fontWeight="fontWeightBold">
          business@untappd.com
        </Box>
      </Box>
    </DialogActions>
  </Dialog>
)

export default EndDialog
