import { Box, Link, styled } from '@mui/material'
import moment from 'moment'
import { red10, yellow10 } from '@theme'

const Parallelogram = styled(Box)({
  transform: 'skew(-30deg)',
})

const Content = styled(Box)({
  fontSize: 12,
  transform: 'skew(30deg)',
})

interface Props {
  date: string
}

const ScheduledDowngrade = ({ date }: Props) => {
  const scheduledDate = moment(date)
  const weekBeforeDowngrade = scheduledDate.clone().subtract(1, 'w')
  const withinWeek = moment().isSameOrAfter(weekBeforeDowngrade)

  return (
    <Parallelogram bgcolor={withinWeek ? red10 : yellow10} ml={6} mx={4}>
      <Content p={3} pl={5}>
        <Box>
          Premium subscription ends on{' '}
          <Box component="span" fontWeight="fontWeightBold">
            {scheduledDate.format('MM/DD/YYYY')}
          </Box>
        </Box>
        <Link
          underline="always"
          sx={{ fontWeight: 'fontWeightBold' }}
          href="https://help.untappd.com/hc/en-us/articles/13225116244116"
          target="_blank"
        >
          View what you need to do before then
        </Link>
      </Content>
    </Parallelogram>
  )
}

export default ScheduledDowngrade
