import { Link as MuiLink, Box, styled } from '@mui/material'
import { ChevronLeft } from 'react-feather'
import { yellow50, gray60, useDown } from '@theme'

const Highlight = styled(Box)`
  border-bottom: 4px solid ${yellow50};
  padding-bottom: 12px;
  margin-bottom: -15px;
  width: 135%;
  margin-left: -12px;
`

const LaunchpadLinkContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
    ml: -1,
    px: 5,
    color: gray60,
    '&:hover': {
      color: gray60,
    },
  }),
)

const Bold = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}))

const Link = styled(MuiLink)({
  color: 'inherit',
})

interface HeaderLeftProps {
  singleVenueLink: boolean
}

const HeaderLeft = ({ singleVenueLink }: HeaderLeftProps) => {
  const downSm = useDown('sm')
  const { location } = window
  const allVenuesMenusUrl = '/app/menus'
  const homeLink = '/'

  const isLaunchpad = location.pathname === homeLink
  const isAllMenus = location.pathname === allVenuesMenusUrl

  if (singleVenueLink) {
    return (
      <Link href={homeLink}>
        <LaunchpadLinkContainer>
          <ChevronLeft />
          <Box>
            {!downSm && <span>Back to </span>}
            <Bold display="inline-block">Launchpad</Bold>
          </Box>
        </LaunchpadLinkContainer>
      </Link>
    )
  }

  return (
    <>
      <Link href={homeLink}>
        <Box mx={downSm ? 4 : 6}>
          <Bold>Launchpad</Bold>
          {isLaunchpad && <Highlight />}
        </Box>
      </Link>
      <Link href={allVenuesMenusUrl}>
        <Bold>All Menus</Bold>
        {isAllMenus && <Highlight />}
      </Link>
    </>
  )
}

export default HeaderLeft
